import {
  AnimationTriggerMetadata,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export function FadeIn(
  timingIn: number,
  height: boolean = false,
): AnimationTriggerMetadata {
  return trigger('fadeIn', [
    transition(':enter', [
      style(height ? { opacity: 0, height: 0 } : { opacity: 0 }),
      animate(
        timingIn,
        style(height ? { opacity: 1, height: 'fit-content' } : { opacity: 1 }),
      ),
    ]),
  ]);
}

export function Collapsible(
  timingIn: number,
  timmingOut: number,
): AnimationTriggerMetadata {
  return trigger('collapsible', [
    transition(':enter', [
      style({
        height: 0,
        overflow: 'hidden',
      }),
      animate(
        timingIn,
        style({
          height: '*',
          overflow: 'visible',
        }),
      ),
    ]),
    transition(':leave', [
      animate(
        timmingOut,
        style({
          height: 0,
          overflow: 'hidden',
        }),
      ),
    ]),
  ]);
}

export function hamburgerIcon(): AnimationTriggerMetadata {
  return trigger('hamburguerX', [
    /*
      state hamburguer => is the regular 3 lines style.
      states topX, hide, and bottomX => used to style the X element
    */
    state('hamburguer', style({})),
    // style top bar to create the X
    state(
      'topX',
      style({
        transform: 'rotate(45deg)',
        transformOrigin: 'left',
        margin: '{{ margin }}',
      }),
      { params: { margin: '10px' } },
    ),
    // hides element when create the X (used in the middle bar)
    state(
      'hide',
      style({
        opacity: 0,
      }),
    ),
    // style bottom bar to create the X
    state(
      'bottomX',
      style({
        transform: 'rotate(-45deg)',
        transformOrigin: 'left',
        margin: '{{ margin }}',
      }),
      { params: { margin: '10px' } },
    ),
    transition('* => *', [
      animate('0.2s'), // controls animation speed
    ]),
  ]);
}

export function Rotate(
  angle: number,
  timing: number,
): AnimationTriggerMetadata {
  return trigger('rotate', [
    /*
      state hamburguer => is the regular 3 lines style.
      states topX, hide, and bottomX => used to style the X element
    */
    state('default', style({})),
    // style top bar to create the X
    state(
      'rotated',
      style({
        transform: `rotate(${angle}deg)`,
        transformOrigin: 'center',
      }),
    ),
    transition('* => *', [
      animate(timing), // controls animation speed
    ]),
  ]);
}
